<template>
  <span
    >{{ price.whole }}<span v-if="props.exact || price.fractional !== '00'" :class="{ 'text-[0.7em]': !props.largeFractional }">,{{ price.fractional }}</span
    >&nbsp;{{ price.symbol }}</span
  >
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    amount?: number | null
    exact?: boolean
    largeFractional?: boolean
  }>(),
  {
    amount: null,
    exact: false,
    largeFractional: false,
  }
)

const price = computed(() => usePrice(props.amount))
</script>
